import React from 'react';
import PropTypes from 'prop-types';
import RightsWrapper from '../../sessionProvider/RightsWrapper';
import Table from '../../general/Table';
import Tile from '../../general/Tile';
import Link from '../../navigation/Link';
import TileAttribute from '../../general/TileAttribute';
import PriceWhole from '../../project/PriceWhole';
import TaxedPriceDisplay from '../../project/TaxedPriceDisplay';
import withDataHOC from '../../dataProvider/withDataHOC';
import locationHOC from '../../locationProvider/locationHOC';

import InputTextArea from '../../general/InputTextArea';
import InputUnitPrice from '../../project/InputUnitPrice';
import ProductEditableWithMovesRQO from '../../project/ProductEditableWithMovesRQO';
import CustomerCodeEditableRQO from '../../project/CustomerCodeEditableRQO';
import InputQuantity from '../../project/InputQuantity';
import RemoveQuotationItem from './RemoveQuotationItem';
import QuotationItemAvailability from './QuotationItemAvailability';

import {ROUTES, QUERY_PARAMS} from '../../../constants/navigation';
import {navigateToParametrized, getQueryParam, getQueryParamNumber} from '../../../lib/url';
import {isQuotationSend, getInputOnChangeEventChecked, getQuotationPricesBySelectedItems, getQuotationLines, getDefaultTablePageSize} from '../../../lib/project';
import {merge} from '../../../lib/object';
import {setSelected as setSelectedLib, isSelected as isSelectedLib} from '../../../lib/tableCheckbox';
import {isNumberAndGreaterThan, numberOrNull, isNumberAndGreaterOrEqual} from '../../../lib/number';
import {formatPrice, formatPercentage, formatQuantity, formatUnitPrice, formatQuantityPerPackage} from '../../../lib/formatting';
import {createFetchQuotationEditItem} from '../../../backend/apiCalls';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {RIGHTS} from '../../../constants/Rights';
import {QUOTATION_DIVISIBILITY_LEVEL} from '../../../constants/divisibility';
import {QuotationDetail, QuotationDetailLine} from '../../../constants/propTypesDefinitions';
import {Button, Checkbox, notification} from 'antd';

import {Trans, t} from '@lingui/macro';
import QuotationAvailability from '../../project/QuotationAvailability';
import PurchasePricesModal from '../../project/productTable/PurchasePricesModal';
import InputNumber from '../../general/InputNumber';
import PackageTypeSelect from '../../project/PackageTypeSelect';
import { roundTo2DecimalNumbers } from '../../../lib/computation';
import QuotationAddProduct from './QuotationAddProduct';
import InputText from '../../general/InputText';
import { stopPropagation } from '../../../lib/interaction';
import UnitPrice from '../../general/UnitPrice';
import SpecialTransportIcon from '../../project/SpecialTransportIcon';
import { SESSION_ATTRIBUTES } from '../../sessionProvider/SessionProvider';
import withSessionHOC from '../../sessionProvider/withSessionHOC';

/**
 * @fero
 */

class QuotationDetailsTable extends React.Component {

    static propTypes = {
        location: PropTypes.object.isRequired,
        quotationDetails: QuotationDetail.isRequired,
        [GLOBAL_DATA.RELOAD_DATA]: PropTypes.func.isRequired,
        selectedItemsIds: PropTypes.arrayOf(PropTypes.number),
        overrideQuantities: PropTypes.objectOf(PropTypes.number.isRequired).isRequired,
        setSelected: PropTypes.func.isRequired,
        setQuantity: PropTypes.func.isRequired,
        resetQuantity: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            showSearch: false,
        };
    }

    setPhrase = (newPhrase) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.QUOTATION_DETAIL_PHRASE]: newPhrase,
        });
    };

    componentDidMount() {
        const {setSelected, resetQuantity} = this.props;
        setSelected(null); // null means all
        resetQuantity();
    }

    componentDidUpdate(prevProps) {
        const {quotationDetails, setSelected, resetQuantity} = this.props;
        const {quotationDetails: prevQuotationDetails} = prevProps;
        if(quotationDetails.id != prevQuotationDetails.id 
            || quotationDetails.divisibility_level != prevQuotationDetails.divisibility_level) {
            setSelected(null);
            resetQuantity();
        }
    }

    shouldUseCheckboxes() {
        const {quotationDetails} = this.props;
        return quotationDetails.divisibility_level >= QUOTATION_DIVISIBILITY_LEVEL.WHOLE_ITEM
    }

    isQuantityEditable() {
        const {quotationDetails} = this.props;
        return quotationDetails.divisibility_level >= QUOTATION_DIVISIBILITY_LEVEL.ANY_QUANTITY
    }

    setOrdering = (newOrder) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.QUOTATION_DETAIL_ORDER_BY]: newOrder,
        });
    };

    setLimit = (newLimit) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.QUOTATION_DETAIL_LIMIT]: newLimit,
        });
    };

    setOffset = (newOffset) => {
        const {location} = this.props;
        navigateToParametrized(location, null, {
            [QUERY_PARAMS.QUOTATION_DETAIL_OFFSET]: newOffset,
        });
    };

    areAllSelected = () => {
        const {quotationDetails, selectedItemsIds} = this.props;
        //naive testing because of performance
        return (selectedItemsIds == null || quotationDetails == null || quotationDetails.lines_count == selectedItemsIds.length);
    };

    render() {
        const {
            quotationDetails = {}, location, selectedItemsIds, overrideQuantities, setSelected, setQuantity,
            [GLOBAL_DATA.RELOAD_DATA]: reload, [SESSION_ATTRIBUTES.SETTINGS]: settings
        } = this.props;
        const {showSearch} = this.state;
        const tableData = getQuotationLines(quotationDetails);
        const orderBy = getQueryParam(location, QUERY_PARAMS.QUOTATION_DETAIL_ORDER_BY);
        const limit = getQueryParamNumber(location, QUERY_PARAMS.QUOTATION_DETAIL_LIMIT);
        const offset = getQueryParamNumber(location, QUERY_PARAMS.QUOTATION_DETAIL_OFFSET);
        const phrase = getQueryParam(location, QUERY_PARAMS.QUOTATION_DETAIL_PHRASE);
        const useVAT = settings.use_vat;
        const shouldUseCheckboxes = this.shouldUseCheckboxes();
        const isQuantityEditable = this.isQuantityEditable();
        const isSent = isQuotationSend(quotationDetails);
        const doSearch = (phrase != null && phrase != "") || showSearch;

        return <div>
            <Table
                BodyRow={isSent ? QuotationDetailsTableRowFixed : QuotationDetailsTableRowEditable}
                BodyTile={QuotationDetailsTile}
                data={tableData}
                customerId={quotationDetails.customer != null ? quotationDetails.customer.id : null}
                reload={() => {
                    reload([GLOBAL_DATA.QUOTATION_DETAILS])
                }}
                footer={{
                    node: <div className="d-flex flex-column justify-content-end align-items-end">
                        <PriceWhole 
                            linesCount={selectedItemsIds != null ? selectedItemsIds.length : quotationDetails.lines_count} 
                            prices={getQuotationPricesBySelectedItems(quotationDetails, selectedItemsIds, overrideQuantities)}
                        />
                        <Trans>(Cena pre vybrané položky)</Trans>
                    </div>,
                    heightRem: 5.5
                }}
                notScrollable={true}
                useVAT={useVAT}
                orderBy={orderBy}
                changeOrder={this.setOrdering}
                limit={limit}
                changeLimit={this.setLimit}
                offset={offset}
                changeOffset={this.setOffset}
                pageSize={getDefaultTablePageSize()}
                totalCount={quotationDetails.lines_count}
                setSelected={setSelected}
                setQuantity={setQuantity}
                selectedItemsIds={selectedItemsIds}
                shouldUseCheckboxes={shouldUseCheckboxes}
                isQuantityEditable={isQuantityEditable}
                overrideQuantities={overrideQuantities}
                isSent={isSent}
                colDefs={[
                    {
                        class: 'quotation-details-table-checkbox',
                        headerCell: <div className="text-center">
                            <Checkbox
                                disabled={!shouldUseCheckboxes}
                                checked={this.areAllSelected()}
                                onChange={(e) => {
                                    const selected = getInputOnChangeEventChecked(e);
                                    if(selected) {
                                        setSelected(null)
                                    } else {
                                        setSelected([]);
                                    }
                                }}
                            />
                        </div>,
                    },
                    {
                        headerCell: <Trans>Č.</Trans>,
                        orderCol: 'sequence',
                        class: 'quotation-details-table-sequence'
                    },
                    {
                        headerCell: doSearch ? 
                            <InputText
                                className="full-size-width"
                                size="small"
                                value={phrase}
                                onChange={this.setPhrase}
                                allowClear={true}
                                placeholder="hľadať ..."
                            />
                            :
                            <div className="d-flex align-items-center">
                                <Trans>Názov produktu a výrobca</Trans>
                                <Button 
                                    size="small"
                                    icon="search" 
                                    className="ml-2"
                                    onClick={(ev) => {
                                        stopPropagation(ev);
                                        this.setState({showSearch: true});
                                    }}
                                />
                            </div>,
                        orderCol: doSearch ? null : 'designation',
                        class: 'quotation-details-table-designation'
                    },
                    {
                        headerCell: <Trans>Vlastné označenie</Trans>,
                        orderCol: 'customer_code',
                        class: 'quotation-details-table-customer-code'
                    },
                    {
                        headerCell: <Trans>Množstvo</Trans>,
                        orderCol: 'package_quantity',
                        class: 'quotation-details-table-quantity'
                    },
                    {
                        headerCell: <Trans>Cena za jednotku</Trans>,
                        orderCol: 'package_price',
                        class: 'quotation-details-table-unit-price'
                    },
                    {
                        headerCell: <Trans>Cena spolu</Trans>,
                        orderCol: 'line_price',
                        class: 'quotation-details-table-price'
                    },
                    useVAT ? {
                        headerCell: <Trans>Spolu s DPH</Trans>,
                        orderCol: 'total_price',
                        class: 'quotation-details-table-whole-price'
                    } : null,
                    {
                        headerCell: <Trans>Dodací termín</Trans>,
                        class: 'quotation-details-table-available-at'
                    },
                    {
                        headerCell: <Trans>Poznámka</Trans>,
                        class: 'quotation-details-table-customer-notice'
                    },
                    {
                        rightsFrom: RIGHTS.MARKETING,
                        headerCell: <Trans>Interná poznámka</Trans>,
                        class: 'quotation-details-table-dealer-notice'
                    },
                    {
                        rightsFrom: RIGHTS.MARKETING,
                        class: isSent ? 'quotation-details-table-actions-empty' : 'quotation-details-table-actions',
                        headerCell: <QuotationAddProduct
                            quotationDetails={quotationDetails}
                        />
                    },
                ]}
            />
        </div>;
    }

}

export default locationHOC(
    withSessionHOC([SESSION_ATTRIBUTES.SETTINGS])(
        withDataHOC([GLOBAL_DATA.RELOAD_DATA])(QuotationDetailsTable)
    )
);

const quotationDetailsItemHOC = (Component) => {
    return class extends React.Component {
        static propTypes = {
            data: QuotationDetailLine.isRequired,
            reload: PropTypes.func.isRequired,
            selectedItemsIds: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
            overrideQuantities: PropTypes.objectOf(PropTypes.number.isRequired).isRequired,
            customerId: PropTypes.number.isRequired,
            setSelected: PropTypes.func.isRequired,
            setQuantity: PropTypes.func.isRequired,
            shouldUseCheckboxes: PropTypes.bool.isRequired,
            isQuantityEditable: PropTypes.bool.isRequired,
            isSent: PropTypes.bool.isRequired,
        };


        editItemQuotation = (queryParams) => {
            const {data, reload, [GLOBAL_DATA.FETCH_HANDLER]: fetchHandler} = this.props;
            fetchHandler(createFetchQuotationEditItem(),
                merge(
                    {
                        id_quotation: data.id_quotation,
                        id_item: data.id_item
                    },
                    queryParams
                ),
                null,
                reload,
                (error) => {
                    notification['error']({ 
                        message: error.message,
                        duration: 10,
                    });
                }
            );
        };

        onProductIdChange = (productId) => {
            this.editItemQuotation({id_product: productId});
        };

        onRequestedAtChange = (requestedAt) => {
            this.editItemQuotation({requested_at: requestedAt});
        };

        onAvailableAtChange = (availableAt) => {
            this.editItemQuotation({available_at: availableAt});
        };

        onSupplyDurationChange = (supplyDuration) => {
            this.editItemQuotation({supply_duration: supplyDuration});
        };

        onCustomerNoticeChange = (newNotice) => {
            this.editItemQuotation({customer_notice: newNotice});
        };

        onDealerNoticeChange = (newNotice) => {
            this.editItemQuotation({dealer_notice: newNotice});
        };

        onCustomerCodeChange = (customerCode) => {
            this.editItemQuotation({customer_code: customerCode});
        };

        onPackageTypeChange = (packageType) => {
            this.editItemQuotation({id_package_type: packageType});
        };

        onPriceChange = (newPrice) => {
            const {reload} = this.props;
            if (isNumberAndGreaterOrEqual(newPrice, 0))
                this.editItemQuotation({package_price: newPrice});
            else
                reload();
        };

        onQuantityChange = (newQuantity) => {
            const {reload} = this.props;
            if (isNumberAndGreaterThan(newQuantity, 0))
                this.editItemQuotation({package_quantity: newQuantity});
            else
                reload();
        };

        onSequenceChange = (newSequence) => {
            const {reload} = this.props;
            if (isNumberAndGreaterThan(newSequence, 0))
                this.editItemQuotation({sequence: newSequence});
            else
                reload();
        };

        onSelectionChange = (ev) => {
            const {data, selectedItemsIds, setSelected} = this.props;
            const selected = getInputOnChangeEventChecked(ev);
            setSelected(setSelectedLib(selected, data.id_item, selectedItemsIds))
        }

        render() {
            const {data: dataOrig, reload, customerId, selectedItemsIds, setSelected, shouldUseCheckboxes, 
                overrideQuantities, setQuantity, isQuantityEditable, ...rest} = this.props;
            let data = {...dataOrig};
            const overrideQuantity = overrideQuantities[data.id_item];
            if(overrideQuantity != null) {
                data.quantity = overrideQuantity;
                data.package_quantity = Number(overrideQuantity) / Number(data.quantity_per_package || 1);
                data.line_price = data.unit_price != null ? roundTo2DecimalNumbers(Number(overrideQuantity) * Number(data.unit_price)) : null;
                data.total_price = data.line_price != null ? roundTo2DecimalNumbers(Number(data.line_price) * (1 + Number(data.tax_rate))) : null;
            }

            const isSelected = isSelectedLib(data.id_item, selectedItemsIds);

            return <Component
                data={data}
                customerId={customerId}
                reload={reload}
                isSelected={isSelected}
                onSelectionChange={this.onSelectionChange}
                setQuantity={setQuantity}
                shouldUseCheckboxes={shouldUseCheckboxes}
                isQuantityEditable={isQuantityEditable}
                onProductIdChange={this.onProductIdChange}
                onRequestedAtChange={this.onRequestedAtChange}
                onAvailableAtChange={this.onAvailableAtChange}
                onSupplyDurationChange={this.onSupplyDurationChange}
                onCustomerNoticeChange={this.onCustomerNoticeChange}
                onDealerNoticeChange={this.onDealerNoticeChange}
                onCustomerCodeChange={this.onCustomerCodeChange}
                onPriceChange={this.onPriceChange}
                onQuantityChange={this.onQuantityChange}
                onSequenceChange={this.onSequenceChange}
                onPackageTypeChange={this.onPackageTypeChange}
                {...rest}
            />
        }
    }
};

const quotationDetailsItem = (Component) => withDataHOC([GLOBAL_DATA.FETCH_HANDLER])(quotationDetailsItemHOC(Component));

class QuotationDetailsTableRowEditableComponent extends React.Component {
    static propTypes = {
        data: QuotationDetailLine.isRequired,
        reload: PropTypes.func.isRequired,
        customerId: PropTypes.number.isRequired,
        onSelectionChange: PropTypes.func.isRequired,
        isSelected: PropTypes.bool.isRequired,
        shouldUseCheckboxes: PropTypes.bool.isRequired,
        isSent: PropTypes.bool.isRequired,
        onProductIdChange: PropTypes.func.isRequired,
        onRequestedAtChange: PropTypes.func.isRequired,
        onAvailableAtChange: PropTypes.func.isRequired,
        onSupplyDurationChange: PropTypes.func.isRequired,
        onCustomerNoticeChange: PropTypes.func.isRequired,
        onDealerNoticeChange: PropTypes.func.isRequired,
        onCustomerCodeChange: PropTypes.func.isRequired,
        onPriceChange: PropTypes.func.isRequired,
        onQuantityChange: PropTypes.func.isRequired,
        onSequenceChange: PropTypes.func.isRequired,
        onPackageTypeChange: PropTypes.func.isRequired,
    };

    constructor(props) {
        super(props);
    }

    quotationItemUnitPrice = () => {
        const {data, onPriceChange} = this.props;
        return <React.Fragment>
            <RightsWrapper to={RIGHTS.WORKER}>
                {formatUnitPrice(data.package_price, data.package_type, data.id_currency)}
            </RightsWrapper>
            <RightsWrapper from={RIGHTS.DEALER}>
                <InputUnitPrice
                    size="small"
                    value={data.package_price}
                    onChange={onPriceChange}
                    hasError={data.package_price == null}
                    currency={data.id_currency}
                />
            </RightsWrapper>
            { data.id_package_type != null ? 
                <div className="table-subdata">
                    {'(' + formatUnitPrice(data.unit_price, data.quantity_units, data.id_currency) + ')'}
                </div> : null
            }
            <RightsWrapper from={RIGHTS.WHOLESALE_CUSTOMER}>
                {data.requested_price != null ? 
                    <div className="table-subdata">
                        <div><Trans>požadovaná cena:</Trans></div>
                        <div>{formatUnitPrice(data.requested_price, data.quantity_units, data.id_currency)}</div>
                    </div> : null
                }
            </RightsWrapper>
            <RightsWrapper from={RIGHTS.DEALER}>
                {data.id_product != null ?
                    <PurchasePricesModal
                        size="small"
                        className="full-size-width mt-1"
                        productId={data.id_product}
                        productDesignation={data.designation}
                        productManufacturer={data.manufacturer}
                    /> : null
                }
            </RightsWrapper>
        </React.Fragment>;
    };

    quotationItemQuantity = () => {
        const {data, onQuantityChange, onPackageTypeChange} = this.props;
        return <React.Fragment>
            <RightsWrapper to={RIGHTS.MARKETING}>
                {formatQuantity(data.package_quantity, data.package_type)}
            </RightsWrapper>
            <RightsWrapper from={RIGHTS.DEALER}>
                <InputQuantity
                    className="text-right"
                    size="small"
                    value={numberOrNull(data.package_quantity)}
                    onChange={onQuantityChange}
                    unit={
                        <PackageTypeSelect
                            size="small"
                            value={data.id_package_type}
                            onChange={onPackageTypeChange}
                            packageInfo={data}
                        />
                    }
                />
            </RightsWrapper>
            { data.quantity_per_package != null ?
                <div className="table-subdata">
                    {formatQuantityPerPackage(data.quantity_per_package, data.quantity_units, data.package_type)}
                </div> :
                null
            }
        </React.Fragment>;
    };

    quotationItemSequence = () => {
        const {data, onSequenceChange} = this.props;
        return [
            <RightsWrapper key="customer" to={RIGHTS.DISTRIBUTOR}>
                {data.sequence}
            </RightsWrapper>,
            <RightsWrapper key="dealer" from={RIGHTS.MARKETING}>
                <InputNumber
                    size="small"
                    className="text-right"
                    value={data.sequence}
                    onChange={onSequenceChange}
                />
            </RightsWrapper>,
        ];
    };

    render() {
        const {
            data, customerId, onProductIdChange, shouldUseCheckboxes, onCustomerNoticeChange, useVAT,
            onDealerNoticeChange, onAvailableAtChange, onSupplyDurationChange, onSelectionChange, isSelected,
        } = this.props;
        return <tr>
            <td className="text-center">
                <Checkbox
                    onChange={onSelectionChange}
                    checked={isSelected}
                    disabled={!shouldUseCheckboxes}
                />
            </td>
            <td>{this.quotationItemSequence()}</td>
            <td>
                <ProductEditableWithMovesRQO
                    productDesignation={data.designation}
                    productManufacturer={data.manufacturer}
                    orderCode={data.order_code}
                    productId={data.id_product}
                    customerId={customerId}
                    onProductIdChange={onProductIdChange}
                    quotationsCount={data.quotations_count}
                />
                <SpecialTransportIcon flag={data.special_transport}/>
            </td>
            <td>
                <CustomerCodeEditableRQO
                    productCustomerCode={data.customer_code}
                />
            </td>
            <td className="text-right">{this.quotationItemQuantity()}</td>
            <td className="text-right">{this.quotationItemUnitPrice()}</td>
            <td className="text-right">
                {formatPrice(data.line_price, data.id_currency)}
            </td>
            { useVAT ? 
                <td className="text-right">
                    <div>{formatPrice(data.total_price, data.id_currency)}</div>
                    <div>{formatPercentage(data.tax_rate)}</div>
                </td>
                : null
            }
            <td>
                <QuotationItemAvailability
                    data={data}
                    onAvailableAtChange={onAvailableAtChange}
                    onSupplyDurationChange={onSupplyDurationChange}
                />
            </td>
            <RightsWrapper key="customer" to={RIGHTS.DISTRIBUTOR}>
                <td><pre>{data.customer_notice}</pre></td>
            </RightsWrapper>
            <RightsWrapper key="dealer" from={RIGHTS.MARKETING}>
                <td>
                    <InputTextArea
                        autoSize={{minRows: 4, maxRows: 6}}
                        value={data.customer_notice}
                        onChange={onCustomerNoticeChange}
                    />
                </td>
                <td>
                    <InputTextArea
                        autoSize={{minRows: 4, maxRows: 6}}
                        value={data.dealer_notice}
                        onChange={onDealerNoticeChange}
                    />
                </td>
                <td>
                    <RemoveQuotationItem
                        data={data}
                    />
                </td>
            </RightsWrapper>
        </tr>;
    }

}

const QuotationDetailsTableRowEditable = quotationDetailsItem(QuotationDetailsTableRowEditableComponent);

class QuotationDetailsTableRowFixedComponent extends React.Component {
    static propTypes = {
        data: QuotationDetailLine.isRequired,
        reload: PropTypes.func.isRequired,
        customerId: PropTypes.number.isRequired,
        isSelected: PropTypes.bool.isRequired,
        onSelectionChange: PropTypes.func.isRequired,
        setQuantity: PropTypes.func.isRequired,
        shouldUseCheckboxes: PropTypes.bool.isRequired,
        isQuantityEditable: PropTypes.bool.isRequired,
        onProductIdChange: PropTypes.func.isRequired,
        onRequestedAtChange: PropTypes.func.isRequired,
        onAvailableAtChange: PropTypes.func.isRequired,
        onSupplyDurationChange: PropTypes.func.isRequired,
        onCustomerNoticeChange: PropTypes.func.isRequired,
        onDealerNoticeChange: PropTypes.func.isRequired,
        onCustomerCodeChange: PropTypes.func.isRequired,
        onPriceChange: PropTypes.func.isRequired,
        onQuantityChange: PropTypes.func.isRequired,
    };

    render() {
        const {data, customerId, isSelected, useVAT, onSelectionChange, shouldUseCheckboxes,
            setQuantity, isQuantityEditable} = this.props;

        return <tr>
            <td className="text-center">
                <Checkbox
                    onChange={onSelectionChange}
                    checked={isSelected}
                    disabled={!shouldUseCheckboxes}
                />
            </td>
            <td>{data.sequence}</td>
            <td>
                <ProductEditableWithMovesRQO
                    productDesignation={data.designation}
                    productManufacturer={data.manufacturer}
                    orderCode={data.order_code}
                    productId={data.id_product}
                    customerId={customerId}
                    quotationsCount={data.quotations_count}
                />
                <SpecialTransportIcon flag={data.special_transport}/>
            </td>
            <td>
                <CustomerCodeEditableRQO
                    productCustomerCode={data.customer_code}
                />
            </td>
            <td className="text-right">
                { isQuantityEditable ?
                    <InputQuantity
                        className="text-right"
                        value={Number(data.package_quantity)}
                        onChange={(newVal) => setQuantity(data.id_item, newVal, data.quantity_per_package)}
                        unit={data.package_type}
                    /> :
                    formatQuantity(data.package_quantity, data.package_type)
                }
                { data.quantity_per_package != null ? 
                    <div className="table-subdata">
                        {formatQuantityPerPackage(data.quantity_per_package, data.quantity_units, data.package_type)}
                    </div> :
                    null
                }
                { Number(data.ordered_quantity) > 0 ? 
                    <div className="table-subdata">
                        <Trans>objednané:</Trans>
                        {' '}
                        {formatQuantity(data.ordered_quantity, data.quantity_units)}
                    </div> : 
                    null
                }
            </td>
            <td className="text-right">
                {formatUnitPrice(data.package_price, data.package_type, data.id_currency)}
                { data.id_package_type != null ?
                    <div className="table-subdata">
                        {'(' + formatUnitPrice(data.unit_price, data.quantity_units, data.id_currency) + ')'}
                    </div> :
                    null
                }
                <RightsWrapper from={RIGHTS.WHOLESALE_CUSTOMER}>
                    {data.requested_price != null ? <div className="table-subdata">
                        <div><Trans>požadovaná cena:</Trans></div>
                        <div>{formatUnitPrice(data.requested_price, data.quantity_units, data.id_currency)}</div>
                    </div> : null}                
                </RightsWrapper>
                <RightsWrapper from={RIGHTS.DEALER}>
                {data.id_product != null ?
                    <PurchasePricesModal
                        size="small"
                        className="full-size-width mt-1"
                        productId={data.id_product}
                        productDesignation={data.designation}
                        productManufacturer={data.manufacturer}
                    /> : null
                }
            </RightsWrapper>
            </td>
            <td className="text-right">
                {formatPrice(data.line_price, data.id_currency)}
            </td>
            { useVAT ? 
                <td className="text-right">
                    <div>{formatPrice(data.total_price, data.id_currency)}</div>
                    <div>{formatPercentage(data.tax_rate)}</div>
                </td>
                : null
            }
            <td><QuotationAvailability supplyDuration={data.supply_duration} availableAt={data.available_at}/></td>
            <td><pre>{data.customer_notice}</pre></td>
            <RightsWrapper from={RIGHTS.MARKETING}>
                <td>
                    <InputTextArea
                        size="small"
                        value={data.dealer_notice}
                        onChange={this.onDealerNoticeChange}
                    />
                </td>
                <td/>
            </RightsWrapper>
        </tr>;
    }

}

const QuotationDetailsTableRowFixed = quotationDetailsItem(QuotationDetailsTableRowFixedComponent);

class QuotationDetailsTileComponent extends React.Component {
    static propTypes = {
        data: QuotationDetailLine.isRequired,
        reload: PropTypes.func.isRequired,
        customerId: PropTypes.number.isRequired,
        onSelectionChange: PropTypes.func.isRequired,
        isSelected: PropTypes.bool.isRequired,
        shouldUseCheckboxes: PropTypes.bool.isRequired,
        isSent: PropTypes.bool.isRequired,
        onProductIdChange: PropTypes.func.isRequired,
        onRequestedAtChange: PropTypes.func.isRequired,
        onAvailableAtChange: PropTypes.func.isRequired,
        onSupplyDurationChange: PropTypes.func.isRequired,
        onCustomerNoticeChange: PropTypes.func.isRequired,
        onDealerNoticeChange: PropTypes.func.isRequired,
        onCustomerCodeChange: PropTypes.func.isRequired,
        onPriceChange: PropTypes.func.isRequired,
        onQuantityChange: PropTypes.func.isRequired,
        onPackageTypeChange: PropTypes.func.isRequired,
    };

    render() {
        const {
            data, customerId, onProductIdChange, onRequestedAtChange, useVAT, onCustomerNoticeChange,
            onDealerNoticeChange, onQuantityChange, onPriceChange, isSent, onAvailableAtChange, onSupplyDurationChange
        } = this.props;
        return <Tile className="p-2">
            <div className="flex-row-dynamic-static">
                <div className="align-self-center align-self-center">
                    <Link
                        className="text-dark"
                        to={ROUTES.PRODUCT_DETAILS}
                        queryParams={{[QUERY_PARAMS.ID_PRODUCT]: data.id_product}}
                    >
                        <h4 className="px-2 mt-1">{data.designation + ' (' + data.manufacturer + ')'}</h4>
                    </Link>
                    <RightsWrapper from={RIGHTS.WHOLESALE_CUSTOMER}>
                        <TileAttribute
                            title={<Trans>Vlastné ozn.</Trans>}
                            value={
                                <CustomerCodeEditableRQO
                                    productCustomerCode={data.customer_code}
                                />
                            }
                        />
                    </RightsWrapper>
                </div>
                <RightsWrapper from={RIGHTS.MARKETING}>
                    <RemoveQuotationItem
                        buttonClassName="m-1"
                        data={data}
                    />
                </RightsWrapper>
            </div>
            <div className="d-flex">
                <TileAttribute
                    title={<Trans>Množstvo</Trans>}
                    value={
                        <React.Fragment>
                            <RightsWrapper to={RIGHTS.DISTRIBUTOR}>
                                <span className="text-nowrap">{formatQuantity(data.package_quantity, data.package_type)}</span>
                            </RightsWrapper>
                            <RightsWrapper from={RIGHTS.MARKETING}>
                                {
                                    isSent ?
                                        <span className="text-nowrap">{formatQuantity(data.package_quantity, data.package_type)}</span> :
                                        <InputQuantity
                                            className="text-right"
                                            size="small"
                                            value={numberOrNull(data.package_quantity)}
                                            onChange={onQuantityChange}
                                            unit={data.package_type}
                                            min={0}
                                            isRequired={true}
                                        />
                                }
                            </RightsWrapper>
                        </React.Fragment>
                    }
                />
                <TileAttribute
                    title={<Trans>Jedn. cena</Trans>}
                    value={
                        <React.Fragment>
                            <RightsWrapper to={RIGHTS.WORKER}>
                                {formatUnitPrice(data.package_price, data.package_type, data.id_currency)}
                            </RightsWrapper>
                            <RightsWrapper from={RIGHTS.DEALER}>
                                {
                                    isSent ?
                                        formatUnitPrice(data.package_price, data.package_type, data.id_currency) :
                                        <InputUnitPrice
                                            size="small"
                                            value={data.package_price}
                                            onChange={onPriceChange}
                                            unit={data.package_type}
                                            currency={data.id_currency}
                                            min={0}
                                            hasError={data.package_price == null}
                                        />
                                }
                            </RightsWrapper>
                        </React.Fragment>
                    }
                />
            </div>
            <TileAttribute
                title={<Trans>Cena spolu</Trans>}
                value={
                    <TaxedPriceDisplay
                        linePrice={data.line_price}
                        totalPrice={data.total_price}
                    />
                }
            />
            { useVAT ?
                <TileAttribute
                    title={<Trans>DPH</Trans>}
                    value={formatPercentage(data.tax_rate)}
                />
                : null
            }
            <TileAttribute
                labelClassName="mt-1 align-self-start"
                title={<Trans>Dodací termín</Trans>}
                value={
                    isSent ?
                    <QuotationAvailability supplyDuration={data.supply_duration} availableAt={data.available_at}/> :
                    <div>
                        <QuotationItemAvailability
                            data={data}
                            onAvailableAtChange={onAvailableAtChange}
                            onSupplyDurationChange={onSupplyDurationChange}
                        />
                    </div>
                }
            />
            <TileAttribute
                className="full-size-width flex-wrap"
                title={<Trans>Poznámka</Trans>}
                value={
                    <React.Fragment>
                        <RightsWrapper to={RIGHTS.DISTRIBUTOR}>
                            <pre>{data.customer_notice}</pre>
                        </RightsWrapper>
                        <RightsWrapper from={RIGHTS.MARKETING}>
                            {isSent ?
                                <pre>{data.customer_notice}</pre> :
                                <InputTextArea
                                    size="small"
                                    value={data.customer_notice}
                                    onChange={onCustomerNoticeChange}
                                />

                            }
                        </RightsWrapper>
                    </React.Fragment>
                }
            />
            <RightsWrapper from={RIGHTS.MARKETING}>
                <TileAttribute
                    className="full-size-width flex-wrap"
                    title={<Trans>Interná poznámka</Trans>}
                    value={
                        <InputTextArea
                            size="small"
                            value={data.dealer_notice}
                            onChange={onDealerNoticeChange}
                        />
                    }
                />
            </RightsWrapper>
            <SpecialTransportIcon flag={data.special_transport} className="px-2"/>
        </Tile>
    }
}

const QuotationDetailsTile = quotationDetailsItem(QuotationDetailsTileComponent);